import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Box, Input, Select, Tabs } from 'components';
import { FormProps } from 'modules/measurements/components/MeasurementFormWrap';
import { SimplifiedMeasurements } from 'modules/measurements/components/SimplifiedMeasurementFormMeasurements';
import { MeasurementFormFooter } from 'modules/measurements/components/MeasurementFormFooter';
import { SimplifiedObservations } from 'modules/measurements/components/SimplifiedMeasurementFormObservations';
import {
  GarmentCategory,
  GarmentFieldSetting,
  GarmentFitSetting,
  GarmentMeasurementInput,
  GarmentMeasurementTweak,
  GarmentObservationTypeSetting,
  MeasurementType,
  useGetSimplifiedMeasurementQuery,
  useUpsertSimplifiedMeasurementMutation,
} from '@graphql';
import { getAbbreviatedDate } from 'helpers/date-helpers';
import { useOnComplete, useOnError, useOnRedirect, getDefaultNameForMeasurement } from 'helpers/measurement-helpers';

export const SimplifiedMeasurementForm = ({ customerId, measurementId, garmentCategory }: FormProps) => {
  const useFormMethods = useForm<GarmentMeasurementInput>();
  const router = useRouter();
  const onComplete = useOnComplete({ router, toast });
  const onError = useOnError(toast);
  const onRedirect = useOnRedirect(router);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useFormMethods;

  const selectedFit = watch('fit');

  const { data, loading } = useGetSimplifiedMeasurementQuery({
    skip: !garmentCategory,
    variables: {
      garmentCategory,
      measurementId,
    },
    onCompleted: (data) => {
      const existingMeasurementData = data.measurement;
      const measurementSettingsData = data.settings.measurement;

      reset({
        name: existingMeasurementData?.name || getDefaultNameForMeasurement({ garmentCategory, dateStr: getAbbreviatedDate() }),
        fit: existingMeasurementData?.fit?.key || null,
        size: existingMeasurementData?.size || null,
        tweaks: measurementSettingsData?.garment.fields.map((field) => ({
          key: field.key,
          value: (existingMeasurementData?.tweaks as unknown as GarmentMeasurementTweak[])?.find((t) => t.key === field.key)?.value || 0,
        })),
        observations: measurementSettingsData?.garment.observations.map((observation) => {
          const foundObservation = existingMeasurementData?.observations.find((o) => o.type.key === observation.key);
          return {
            type: observation.key,
            option: foundObservation?.option.key || observation.items.find((i) => i.value === 0)?.key,
          };
        }),
      });
    },

    onError: (error) => {
      console.error(error);
    },
  });

  const [upsertSimplifiedMeasurement, { loading: isMutationLoading }] = useUpsertSimplifiedMeasurementMutation({
    onCompleted: ({ upsertSimplifiedMeasurement: { id } }) =>
      onComplete({
        measurementId,
        customerId,
        orderId: router.query?.orderId as string,
        designId: router.query?.designId as string,
        newMeasurementId: measurementId ? '' : id,
        measurementType: MeasurementType.Simplified,
      }),
    onError,
  });

  const onSubmit = handleSubmit(async (formData) => {
    // find the block size for the size and save it for future reference
    const fields = data?.settings.measurement.garment.fits
      .find((fit) => fit?.key === formData?.fit)
      ?.sizes.find((size) => size?.key === formData?.size)?.fields;

    try {
      await upsertSimplifiedMeasurement({
        variables: {
          customerId,
          simplifiedMeasurementInput: {
            id: measurementId,
            name: formData.name,
            garmentCategory,
            fit: formData.fit,
            size: formData.size,
            tweaks: formData.tweaks,
            observations: formData.observations.filter((observation) => observation.option),
            fields: fields ? fields.map(({ key, value }) => ({ key, value })) : undefined,
          },
        },
      });
    } catch (error) {
      console.error('Error when submitting tweak garment measurement form', error);
    }
  });

  const onCancel = () => onRedirect({ customerId, orderId: router.query?.orderId as string });

  return (
    <>
      {garmentCategory && (
        <FormProvider {...useFormMethods}>
          <form>
            <div className="mb-4">
              <Box isLoading={loading}>
                <div className="grid grid-cols-8 gap-6">
                  <div className="col-span-8">
                    <Input
                      label="Name"
                      htmlProps={{
                        id: 'name',
                        type: 'text',
                      }}
                      register={register('name', {
                        required: 'Please add a name',
                      })}
                      errorMessage={errors.name?.message}
                    />
                  </div>
                  <div className="col-span-4">
                    <Select
                      label="Fit"
                      htmlProps={{
                        id: 'fit',
                      }}
                      register={register('fit', {
                        required: 'Please select a fit',
                      })}
                    >
                      <option value="">Choose a fit</option>
                      {data?.settings.measurement.garment.fits.map((fit) => (
                        <option key={fit.key} value={fit.key}>
                          {fit.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-span-4">
                    <Select
                      label="Size"
                      register={register('size', {
                        required: 'Please select a size',
                      })}
                      htmlProps={{
                        id: 'size',
                      }}
                    >
                      <option value="">Choose a size</option>
                      {selectedFit &&
                        data?.settings.measurement.garment.fits
                          .find((fit) => fit.key === selectedFit)
                          ?.sizes.map((size) => (
                            <option key={size.key} value={size.key}>
                              {/*  TODO: check what to use as label here  */}
                              {size.name || size.key}
                            </option>
                          ))}
                    </Select>
                  </div>
                </div>
              </Box>
            </div>
            <Box hasPadding={false} isLoading={loading}>
              <Tabs
                tabsListClassName="-mb-px flex"
                panelsClassName="p-6"
                buttonClassName="flex-1 py-4 border-r last:border-r-0"
                tabs={[
                  {
                    name: 'Measurements',
                    content: (
                      <SimplifiedMeasurements
                        fields={data?.settings.measurement.garment.fields as GarmentFieldSetting[]}
                        fits={data?.settings.measurement.garment.fits as GarmentFitSetting[]}
                        shouldValidateSleeveLength={data?.settings?.measurement?.garment?.shouldValidateSleeveLength}
                        garmentCategory={garmentCategory as GarmentCategory}
                      />
                    ),
                  },
                  {
                    name: 'Observations',
                    isHidden: !data?.settings.measurement.garment.observations.length,
                    content: (
                      <SimplifiedObservations observations={data?.settings.measurement.garment.observations as GarmentObservationTypeSetting[]} />
                    ),
                  },
                ]}
              />
            </Box>
            <MeasurementFormFooter isDisabled={isMutationLoading} isNew={!measurementId} onCancel={onCancel} onSubmit={onSubmit} />
          </form>
        </FormProvider>
      )}
    </>
  );
};

SimplifiedMeasurementForm.fragments = {
  MeasurementFragment: gql`
    fragment MeasurementFragment on Measurement {
      id
      name
      fit {
        key
        name
      }
      size
      tweaks {
        __typename
        ... on GarmentMeasurementTweak {
          key
          value
        }
        ... on TryOnMeasurementTweak {
          typeKey
          itemKey
          option
        }
      }
      observations {
        type {
          key
          name
        }
        item {
          key
          name
        }
        option {
          key
          name
        }
      }
    }
  `,
};

SimplifiedMeasurementForm.query = gql`
  query GetSimplifiedMeasurement($garmentCategory: GarmentCategory!, $measurementId: ID) {
    measurement(measurementId: $measurementId) {
      ...MeasurementFragment
    }
    settings {
      id
      measurement {
        garment(garmentCategory: $garmentCategory, includeCalculatedFields: true) {
          shouldValidateSleeveLength
          fields {
            key
            name
            isHalved
            isRequired
            basedOn
            tweaks
            info(type: SIMPLIFIED) {
              text
              image
            }
            isOffset
            range {
              min
              max
            }
          }
          fits {
            key
            name
            sizes {
              key
              name
              fields {
                key
                value
              }
            }
          }
          observations {
            key
            name
            items {
              key
              name
              value
              imageUrl
              description
            }
          }
        }
      }
    }
  }
  ${SimplifiedMeasurementForm.fragments.MeasurementFragment}
`;

SimplifiedMeasurementForm.mutations = {
  UpsertSimplifiedMeasurement: gql`
    mutation UpsertSimplifiedMeasurement($customerId: ID, $simplifiedMeasurementInput: SimplifiedMeasurementInput!) {
      upsertSimplifiedMeasurement(customerId: $customerId, simplifiedMeasurementInput: $simplifiedMeasurementInput) {
        ...MeasurementFragment
      }
    }
    ${SimplifiedMeasurementForm.fragments.MeasurementFragment}
  `,
};
