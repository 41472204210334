import { FormProvider, useForm } from 'react-hook-form';
import { lowerCase, orderBy, startCase } from 'lodash';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

import { Box, Input, Select } from 'components';
import { AppliedMeasurements } from 'modules/measurements/components/AppliedMeasurements';
import { MeasurementFormFooter } from 'modules/measurements/components/MeasurementFormFooter';
import { FormProps } from 'modules/measurements/components/MeasurementFormWrap';
import {
  GarmentAllowance,
  AppliedMeasurementInput,
  GarmentMeasurementField,
  BodyMeasurementField,
  useGetAppliedMeasurementQuery,
  GetBodyMeasurementQueryResult,
  useUpsertAppliedMeasurementMutation,
  MeasurementType,
  MeasurementUnit,
} from '@graphql';
import { getAbbreviatedDate } from 'helpers/date-helpers';
import { useOnComplete, useOnError, useOnRedirect, getDefaultNameForMeasurement, getUnitLongName } from 'helpers/measurement-helpers';
import { useMeasurementUnit } from 'modules/common/MeasurementUnit';
import { Button } from 'modules/common';

type BodyMeasurementFields = GetBodyMeasurementQueryResult['data']['measurement']['fields'];

export const AppliedMeasurementForm = ({ customerId, measurementId, garmentCategory }: FormProps) => {
  const router = useRouter();
  const useFormMethods = useForm<AppliedMeasurementInput>();
  const onComplete = useOnComplete({ router, toast });
  const onError = useOnError(toast);
  const onRedirect = useOnRedirect(router);
  const measurementUnitUtils = useMeasurementUnit();
  const {
    convertFromServerUnit,
    convertToServerUnit,
    updateMeasurementUnit,
    convertUnit,
    measurementUnitInfo: { current: currentMeasurementUnit },
  } = measurementUnitUtils;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    getValues,
    watch,
  } = useFormMethods;

  const { data, loading } = useGetAppliedMeasurementQuery({
    skip: !garmentCategory,
    variables: {
      garmentCategory,
      measurementId,
      customerId: customerId,
    },
    onCompleted: ({ measurement, measurements, settings }) => {
      const latestBodyMeasurement = orderBy(
        measurements?.filter((m) => m.type.key === 'BODY'),
        ['updatedAt.origin'],
        ['desc']
      )[0];

      const fields = measurement?.fields || latestBodyMeasurement?.fields;
      const tweaks = measurement?.tweaks || settings.measurement.garment.fields.map((f) => ({ key: f.key, value: 0 }));

      reset({
        name: measurement?.name || getDefaultNameForMeasurement({ garmentCategory, dateStr: getAbbreviatedDate() }),
        fit: measurement?.fit?.key || null,
        fields: fields?.map((f) => ({ key: f.key as BodyMeasurementField, value: f.value })),
        tweaks: tweaks.map((f: { key: GarmentMeasurementField; value: number }) => ({ key: f.key, value: convertFromServerUnit(f.value) })),
      });
    },
  });

  const [upsertAppliedMeasurement, { loading: isMutationLoading }] = useUpsertAppliedMeasurementMutation({
    onCompleted: ({ upsertAppliedMeasurement: { id } }) =>
      onComplete({
        measurementId,
        customerId,
        orderId: router.query?.orderId as string,
        designId: router.query?.designId as string,
        newMeasurementId: measurementId ? '' : id,
        measurementType: MeasurementType.Applied,
      }),
    onError,
  });

  const onSubmit = handleSubmit(async (formData) => {
    try {
      await upsertAppliedMeasurement({
        variables: {
          customerId,
          appliedMeasurementInput: {
            id: measurementId,
            name: formData.name,
            garmentCategory,
            fit: formData.fit,
            fields: formData.fields,
            tweaks: formData.tweaks.map((t) => ({ ...t, value: convertToServerUnit(t.value) })),
          },
        },
      });
    } catch (error) {
      console.error('Error when submitting applied garment measurement form', error);
    }
  });

  const onCancel = () => onRedirect({ customerId, orderId: router.query?.orderId as string });

  const onFitChange = (e) => {
    const fit = e.target.value;

    setValue(`fit`, fit);

    data?.settings.measurement.garment.fields.forEach((f, i) =>
      setValue(`tweaks.${i}.value`, f.allowances?.[fit] ? convertFromServerUnit(f.allowances?.[fit]) : 0)
    );
  };

  const bodyFields = watch('fields');
  const tweakFields = watch('tweaks');

  const AppliedFits: Array<keyof GarmentAllowance> = ['COMFORTABLE', 'REGULAR', 'SLIM'];

  const nextMeasurementUnit = currentMeasurementUnit === MeasurementUnit.Cm ? MeasurementUnit.Inch : MeasurementUnit.Cm;

  return (
    <>
      {garmentCategory && (
        <FormProvider {...useFormMethods}>
          <form>
            <div className="mb-4">
              <Box isLoading={loading}>
                <div className="grid grid-cols-8 gap-6">
                  <div className="col-span-4">
                    <Input
                      label="Name"
                      htmlProps={{ id: 'name', type: 'text' }}
                      register={register('name', { required: 'Please add a name' })}
                      errorMessage={errors.name?.message}
                    />
                  </div>
                  <div className="col-span-4">
                    <Select
                      label="Fit"
                      htmlProps={{ id: 'fit' }}
                      register={register('fit', { required: 'Please select a fit', onChange: onFitChange })}
                      errorMessage={errors.fit?.message}
                    >
                      <option value="">Choose a fit</option>
                      {AppliedFits.map((fit) => (
                        <option key={fit} value={fit}>
                          {startCase(lowerCase(fit))}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </Box>
            </div>
            <Box isLoading={loading}>
              <div className="flex w-full justify-between items-center mb-4">
                <h2 className="font-semibold text-xl">Measurements</h2>
                <Button
                  className="ml-auto"
                  size="xs"
                  variant="neutral"
                  onClick={() => {
                    tweakFields.forEach((_, i) => {
                      const currentValue = getValues(`tweaks.${i}.value`);
                      const convertedValue = convertUnit(currentValue, nextMeasurementUnit);
                      setValue(`tweaks.${i}.value`, convertedValue);
                    });
                    updateMeasurementUnit(nextMeasurementUnit);
                  }}
                >{`Convert to ${getUnitLongName(nextMeasurementUnit)}`}</Button>
              </div>
              <AppliedMeasurements
                measurementUnitUtils={measurementUnitUtils}
                fields={data?.settings.measurement.garment.fields.filter((f) => f.bodyMeasurementKey)}
                bodyFields={bodyFields as BodyMeasurementFields}
                garmentCategory={garmentCategory}
              />
            </Box>
            <MeasurementFormFooter isDisabled={isMutationLoading} isNew={!measurementId} onCancel={onCancel} onSubmit={onSubmit} />
          </form>
        </FormProvider>
      )}
    </>
  );
};
